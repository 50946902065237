import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import gif from '../assets/gif1.gif';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Story2 extends Component {

    render() {
        return (

            <div class="boxWrap3">

                <div class="storyH"><span class="color1">ABOUT</span></div>

                <div class="about2">

                    <img class="gif" src={gif}/>

                    <div>
                    <p class="storyCon2">With the community first, we seek to entertain, build, and above all enjoy. Let's put the seriousness aside for a while just for a while please</p>					
                    <p class="storyCon2">PepeHatesNfts  come from three important lineages in the Web3 and NFT universe: Rare Pepe,  Bored Ape Yacht Club and now GodHatesNFTees.</p>
                    <p class="storyCon2">10000 PePe NFTs just for the culture 2022 from Crooklyn NY</p>
                    </div>
                </div>


            </div>
        )
    }
}

export default Story2;

