import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import slim from '../assets/slime.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">

                <div class="footer">
				<div class="slim"><img src={slim} /></div>

                    <div class="logoF">Pepe Hates NFTs</div>

                    <div class="copyright">Copyright © 2022 PHNFTs. All Rights Reserved</div>
                </div>

            </div>
        )
    }
}

export default Footer;

