import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Story2 extends Component {

    render() {
        return (

            <div class="boxWrap3">

                <div class="storyH"><span class="color1">ROAD</span><span class="color2">MAP</span></div>

                <div class="about2">
                    <div class="description2">The Roadmap is useless, especially when you are not going to comply with it; why over-promise when we get up every day, set ourselves a community goal and carry it out as we fucking want</div>					

                </div>


            </div>
        )
    }
}

export default Story2;

